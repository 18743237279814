import { Button, MaxWidth, Page, PageMain } from "@puregym/ui";
import styled from "styled-components";

type ExtendedVariantProp = {
  variant: "primary" | "subdued" | "plain" | "dark";
};

type ExtendedDensityProp = {
  density: "default" | "compact" | "collapse";
};

type ExtendedColumnsProps = {
  gap: "single" | "double";
  emphasize?: "left" | "right" | "default";
};

/** Styling to allow for 100% height of children and prevent unwanted scrollbar */
const StyledPage = styled(Page)`
  height: 100%;
`;

const StyledPageMainSearch = styled(PageMain)`
  overflow: hidden;
  height: 100%;
  flex-grow: 1;
`;

const StyledPageMainGym = styled(PageMain)`
  height: 100%;
  flex-grow: 1;
`;

const PageSectionsWrapper = styled.div`
  padding-block-start: ${({ theme }) => theme.spacing.baseSpacing};
`;

const GymSearchGrid = styled.div`
  display: grid;
  grid-template-rows: 100%;
  position: relative;
  z-index: 0;
  flex-grow: 1;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: minmax(300px, 35%) 65%;
  }
`;

const Iframe = styled.iframe`
  display: block;
  max-width: 100%;
  aspect-ratio: 16/9;
  border: 0;
  width: 1000px;
  height: 100%;
`;

const ContainerWrapper = styled.div<ExtendedVariantProp>`
  background: ${({ theme: { colors }, variant }) => {
    switch (variant) {
      case "primary":
        return `${colors.primary.main};`;
      case "subdued":
        return `${colors.accents.muted};`;
      case "plain":
        return `${colors.background};`;
      case "dark":
        return `${colors.accents.dark}`;
    }
  }};
  color: ${({ theme: { colors }, variant }) => {
    switch (variant) {
      case "dark":
        return `${colors.contrastText}`;
    }
  }};
`;

const InnerWrapper = styled(MaxWidth)<ExtendedDensityProp>`
  padding: ${({ theme: { spacing }, density }) => {
    switch (density) {
      case "default":
        return `${spacing.doubleSpacing}`;
      case "compact":
        return `${spacing.baseSpacing}`;
      case "collapse":
        return `0`;
    }
  }};

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-inline: ${({ theme }) => theme.spacing.baseSpacing};
  }
`;

const Columns = styled.div<ExtendedColumnsProps>`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme, gap }) => {
    switch (gap) {
      case "single":
        return `${theme.spacing.baseSpacing};`;
      case "double":
        return `${theme.spacing.doubleSpacing};`;
    }
  }};

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: ${({ emphasize }) => {
      switch (emphasize) {
        case "left":
          return "2fr 1fr";
        case "right":
          return "1fr 2fr";
        default:
          return "1fr 1fr";
      }
    }};
  }
`;

const FlexColumns = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.doubleSpacing};

  > * {
    flex: 1;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.doubleSpacing};

  > * {
    margin: 0;
  }
`;

const CenteredNarrow = styled(Centered)`
  width: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 75%;
    margin: auto;
  }
`;

const CenteredNarrowNoGap = styled(CenteredNarrow)`
  gap: 0;
`;

const CenteredNoGap = styled(Centered)`
  gap: 0;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.halfSpacing};
`;

const RowCentered = styled(Row)`
  justify-content: center;
  text-align: center;
`;

const WideButton = styled(Button)`
  min-width: 25%;
  text-align: center;
`;

const BlockImage = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
`;

const RoundedImage = styled(BlockImage)`
  border-radius: ${({ theme }) => theme.radii.default};
`;

const MarkdownText = styled.div`
  & * {
    margin: 0;
  }
`;

export {
  Centered,
  CenteredNarrow,
  CenteredNarrowNoGap,
  CenteredNoGap,
  Columns,
  ContainerWrapper,
  FlexColumns,
  GymSearchGrid,
  Iframe,
  BlockImage,
  InnerWrapper,
  MarkdownText,
  PageSectionsWrapper,
  RoundedImage,
  Row,
  RowCentered,
  StyledPage,
  StyledPageMainGym,
  StyledPageMainSearch,
  WideButton,
};
