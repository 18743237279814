import { Address } from "@puregym/acquisition-models/gym";

const fullAddress = (address: Address) => {
  const { address1, address2, address3, postalCode } = address;
  const addressParts = [address1, address2, address3, postalCode].filter(
    (part) => part
  );

  return new Intl.ListFormat(process.env.GATSBY_LOCALE, {
    style: "narrow",
    type: "conjunction",
  }).format(addressParts);
};

export default fullAddress;
