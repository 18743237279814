import labels from "../components/labels";

export type DisplayType = "list" | "map";

export const GYM_TAB_TITLES = [
  labels.overview,
  labels.personalTrainers,
  labels.classes,
];

export const GYM_TAB_TITLES_NO_PTS = [labels.overview, labels.classes];
