/**
 * Take a point and return all gyms within a certain radius of it; recursively
 * searches within an increasing radius up to (and including) the max.
 *
 * @param {object} startingLocation - Object containing latitude and longitude
 * @param {array} gyms - `gym.location` is an object with latitude and longitude keys
 * @param {number} minRadius - Defines initial search radius in kms
 * @param {number} maxRadius - Defines max search radius in kms
 * @returns {gyms} array containing a list of gyms and {noGymsFoundInRange} bool value which will be true if no gyms were found in the specified max range
 * @example
 * // With default settings
 * calculateNearbyGyms([22.9068, 43.1729], gyms)
 *
 * // Custom search radius
 * calculateNearbyGyms([22.9068, 43.1729], gyms, 250, 2000)
 *
 */
const calculateNearbyGyms = (
  startingLocation,
  gyms,
  radius = 10,
  maxRadius = 1000
) => {
  const nearbyGyms = gyms.filter(
    (gym) => gym.distanceFromSearchLocation <= radius
  );

  // If we found gyms within the search radius, return them.
  if (nearbyGyms.length) {
    return [nearbyGyms, false];
  }

  if (radius == maxRadius) {
    return [gyms, true];
  }

  // try again with a larger radius - we multiple by 2 each time.
  return calculateNearbyGyms(
    startingLocation,
    gyms,
    Math.min(radius * 2, maxRadius),
    maxRadius
  );
};

export default calculateNearbyGyms;
