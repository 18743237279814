const createMapIcon = (width, height, image) => {
  return {
    width,
    height,
    iconSize: [width, height],
    iconAnchor: [width / 2, height],
    iconUrl: image,
  };
};

export default createMapIcon;
