import MonthlyPackage from "@puregym/acquisition-models/monthlyPackage";
import labels from "../components/labels";
import dayjs from "./dayjs";
import { formatSalesTax } from "./formatSalesTax";
import { getPluralLabel, replaceTemplates } from "./helpers";
import { replaceTemplates as replaceTemplate } from "./replaceTemplates";

const normalCostBreakdown = (cheapestPackage: MonthlyPackage) => {
  const replacements = new Map([
    [
      "currentPrice",
      `<span>${formatSalesTax(
        cheapestPackage?.currentPrice,
        cheapestPackage?.salesTax
      )}</span>`,
    ],
  ]);
  return replaceTemplates(labels.gymCard.costBreakdown, replacements);
};

const offerCostBreakdown = (cheapestPackage: MonthlyPackage) => {
  const replacements = new Map([
    [
      "normalPrice",
      `<span>${formatSalesTax(
        cheapestPackage?.normalPrice,
        cheapestPackage?.salesTax
      )}</span><br>`,
    ],
    [
      "currentPrice",
      `<span>${formatSalesTax(
        cheapestPackage?.currentPrice,
        cheapestPackage?.salesTax
      )}</span>`,
    ],
  ]);

  return replaceTemplates(labels.gymCard.offerCostBreakdown, replacements);
};

const termCostBreakdown = (cheapestPackage: MonthlyPackage) => {
  const offerLength = dayjs
    .duration(cheapestPackage?.discountPeriod)
    .asMonths();

  const replacements = new Map<string, string | number>([
    ["discountPeriod", offerLength],
    [
      "joiningFee",
      formatSalesTax(
        cheapestPackage?.currentJoiningFee,
        cheapestPackage?.joiningFeeSalesTax
      ),
    ],
  ]);

  return getPluralLabel(
    labels.gymCard.discountedCostBreakdown,
    offerLength,
    replacements
  );
};

const fullCostBreakdown = (chepeastPackage: MonthlyPackage): string => {
  if (!chepeastPackage) {
    return null;
  }

  const replacements = {
    currentPrice: formatSalesTax(
      chepeastPackage.currentPrice,
      chepeastPackage.salesTax
    ),
    currentJoiningFee: formatSalesTax(
      chepeastPackage.currentJoiningFee,
      chepeastPackage.joiningFeeSalesTax
    ),
  };

  return replaceTemplate(labels.costBreakdown.full, replacements);
};

export {
  fullCostBreakdown,
  normalCostBreakdown,
  offerCostBreakdown,
  termCostBreakdown,
};
