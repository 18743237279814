import { initCookieConsent } from "@puregym/cookieconsent";
import { updateAppElement } from "@puregym/ui";
import { GatsbyBrowser } from "gatsby";
import App from "./src/app";

export const wrapPageElement = ({ element, props }) => (
  <App {...props}>{element}</App>
);

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  updateAppElement("#___gatsby");

  if (process.env.GATSBY_TEST_MODE) {
    window["dataLayer"] = [];
  }
};

export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] =
  () => {
    updateAppElement("#___gatsby");
    process.env.GATSBY_BRAND === "PGUK" && initCookieConsent("EN");
  };
