import { useLocation } from "@reach/router";
import PluginFooter from "@puregym/gatsby-plugin-footer";
import { useDeviceInfo } from "@puregym/ui";
import { ClientOnly } from "@puregym/utilities";
import labels from "../labels";
import { useBrand } from "../providers/brandState";

const Footer = () => {
  const { isEmbeddedBrowser } = useDeviceInfo();
  const location = useLocation();
  const brand = useBrand();
  const locales = JSON.parse(process.env.GATSBY_LOCALES);
  const basePaths = {};
  for (const locale of locales) {
    const gymsOrigin =
      location.pathname.startsWith("/gyms/") ||
      location.pathname.startsWith(
        `/${process.env.GATSBY_LOCALE.toLowerCase()}/gyms/`
      );
    const cityOrigin =
      location.pathname.startsWith("/city/") ||
      location.pathname.startsWith(
        `/${process.env.GATSBY_LOCALE.toLowerCase()}/city/`
      );

    if (gymsOrigin) {
      basePaths[locale] = "gyms";
    }
    if (cityOrigin) {
      basePaths[locale] = "city";
    }
  }
  const shouldDisplayNavigation =
    location.pathname !== "/gyms/" &&
    location.pathname !== `/${process.env.GATSBY_LOCALE.toLowerCase()}/gyms/`;

  const footer = (
    <PluginFooter
      data-testid="footer"
      brand={brand}
      localizedBasePaths={basePaths}
      defaultLocale={process.env.GATSBY_DEFAULT_LOCALE}
      rootDomain={process.env.GATSBY_ROOT_DOMAIN}
      locales={locales}
      title={labels.shared.core.languageSelectorTitle}
      showNavigation={shouldDisplayNavigation}
    />
  );

  return (
    <ClientOnly fallback={footer}>
      {isEmbeddedBrowser ? null : footer}
    </ClientOnly>
  );
};

export default Footer;
