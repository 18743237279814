import { pushDataLayer } from "@puregym/gatsby-plugin-gtm";
import produce from "immer";
import { ACTIONS } from "./constants";
import { GymsState } from "./context";
import {
  calculateNearbyGyms,
  distanceBetween,
  isBetween,
  roundNumber,
} from "./utilities";

const initialState: GymsState = {
  gyms: [],
  filteredGyms: [],
  mapReady: false,
  isSearching: false,
  displaySearchError: false,
  searchBounds: undefined,
  searchLocation: undefined,
  searchSuccessfullyPerformed: undefined,
  numSearchResults: undefined,
  noGymsFoundInRange: undefined,
  searchTerm: "",
  urls: undefined,
};

const sendGoogleTagEvent = (lat, long, searchResults, searchQuery) => {
  const gymMap = searchResults.map((item) => ({
    gymName: item.name,
    distance: `${roundNumber(item.distanceFromSearchLocation, 2)}`,
  }));
  const tagEventpayload = {
    position: { latitude: lat, longitude: long },
    gymSearchResults: gymMap,
    ...(searchQuery && { searchTerm: searchQuery }),
  };

  pushDataLayer({ gym_search: undefined }); // This is to ensure clean update, or dataLayer does a recursive merge of the arrays leading to wrong results

  pushDataLayer({
    event: searchQuery ? "gymSearchKeyword" : "gymSearchGeo",
    gym_search: tagEventpayload,
    numResults: gymMap.length,
  });
};

/**
 * State is wrapped in `produce` from immer.
 *
 * The map generates initial bounds from the main gyms array.
 * To manipulate the map after it has loaded, update the value
 * of `state.searchBounds`. You must be explicit, e.g. to "reset"
 * the map, pass it an array of all gyms, not null or undefined.
 *
 * @see https://immerjs.github.io/immer/docs/update-patterns
 **/
const gymSearchReducer = produce((state: GymsState, action) => {
  const { type, payload } = action;

  if (process.env.GATSBY_DEBUG_LOGGING === "true") {
    console.log("%c Action: ", "background: #222; color: #bada55", action);
  }

  switch (type) {
    case ACTIONS.MAP_READY: {
      state.mapReady = true;

      return;
    }

    case ACTIONS.GET_LOCATION: {
      state.displaySearchError = false;
      state.isSearching = true;

      return;
    }

    /**
     * Success! We have a location (from either search or geolocation)
     * payload is location object with `latitude` and `longitude` keys
     */
    case ACTIONS.GET_LOCATION_SUCCESS: {
      /** Augment all gyms with the distance from our search location */
      state.gyms.forEach(
        (gym) =>
          (gym.distanceFromSearchLocation = distanceBetween(
            gym.location,
            payload
          ))
      );

      /** @todo Formalise and improve some of this - what is nearby? */
      const { min, max } = JSON.parse(process.env.GATSBY_SEARCH_RADII);
      const [nearbyGyms, noGymsFoundInRange] = calculateNearbyGyms(
        payload,
        state.gyms,
        min,
        max
      );

      /** Set map bounds to nearby gyms + search location */
      const nearbyGymsBounds = nearbyGyms.map((gym) => [
        gym.location.latitude,
        gym.location.longitude,
      ]);

      const bounds = [
        ...nearbyGymsBounds,
        [payload.latitude, payload.longitude],
      ];

      state.searchLocation = payload;
      state.searchBounds = bounds;
      state.isSearching = false;
      state.searchSuccessfullyPerformed = true;
      state.numSearchResults = nearbyGyms.length;
      state.noGymsFoundInRange = noGymsFoundInRange;

      sendGoogleTagEvent(
        payload.latitude,
        payload.longitude,
        nearbyGyms,
        payload.searchTerm
      );

      return;
    }

    /** Reset everything on error.
     * @todo Needs improving (as part of messaging/error handling overall) */
    case ACTIONS.GET_LOCATION_ERROR: {
      state.filteredGyms = state.gyms;
      state.searchLocation = undefined;
      state.displaySearchError = true;
      state.searchSuccessfullyPerformed = false;
      state.numSearchResults = 0;
      state.isSearching = false;
      state.searchTerm = "";
      state.searchBounds = state.gyms.map((gym) => [
        gym.location.latitude,
        gym.location.longitude,
      ]);

      return;
    }

    case ACTIONS.MAP_VIEWPORT_CHANGE: {
      const { latitudes, longitudes } = payload;
      const visibleGyms = state.gyms.filter(
        (gym) =>
          isBetween(latitudes, gym.location.latitude) &&
          isBetween(longitudes, gym.location.longitude)
      );

      state.filteredGyms = visibleGyms;

      return;
    }

    case ACTIONS.UPDATE_SEARCH_TERM: {
      state.searchTerm = payload;
      state.searchSuccessfullyPerformed = false;
      state.numSearchResults = 0;
      return;
    }

    case ACTIONS.RESET_GYMS: {
      state.displaySearchError = false;
      state.searchSuccessfullyPerformed = false;
      state.numSearchResults = 0;
      state.filteredGyms = state.gyms;
      state.gyms.forEach((gym) => delete gym.distanceFromSearchLocation);
      state.searchTerm = "";
      state.searchLocation = undefined;
      state.searchBounds = state.gyms.map((gym) => [
        gym.location.latitude,
        gym.location.longitude,
      ]);

      return;
    }

    default: {
      // do nothing
    }
  }
});

export { initialState };

export default gymSearchReducer;
