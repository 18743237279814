import { SkipLink, ThemeProvider, pureGymTheme } from "@puregym/ui";
import "@puregym/ui/dist/static/css/fonts.css";
import { graphql, useStaticQuery } from "gatsby";
import Header from "../src/components/layout/header";
import labels from "./components/labels";
import Footer from "./components/layout/footer";
import BrandStateProvider from "./components/providers/brandState";
import { GymSearchProvider, GymsState } from "./context";
import { initialState } from "./reducer";
import { StyledPage } from "./styles/Layout";

/**
 * Main entrypoint, contains context providers and top-level UI components.
 * `props.children` are rendered inside the `PageMain` component.
 *
 * This component wraps the "Page" Element built by Gatsby and
 * passed to `wrapPageElement` in our `gatsby-browser` and `gastsby-ssr` files.
 * @see https://www.gatsbyjs.com/docs/browser-apis/#wrapPageElement
 * @see https://www.gatsbyjs.com/docs/api-files/
 *
 * See `GymSearchProvider` for search context.
 * @see https://reactjs.org/docs/context.html#contextprovider
 *
 * `ThemeProvider` provides access to the current theme.
 * @see https://bitbucket.org/puregym/pure.ui/
 * @see https://styled-components.com/docs/advanced#theming
 *
 */
const App = ({ children, data }) => {
  /**
   * Retrieve array of all gyms at build time.
   * @see https://www.gatsbyjs.com/docs/use-static-query/
   */
  const { allGym, urls } = useStaticQuery(graphql`
    {
      allGym {
        nodes {
          name
          gymId
          email
          slug
          address {
            address1
            address2
            address3
            postalCode
          }
          location {
            latitude
            longitude
          }
          monthly {
            packages {
              accessLevel
              normalPrice
              currentPrice
              salesTax
              joiningFeeSalesTax
            }
          }
          status
          joinable
        }
      }
      urls {
        authLogin
        authLogout
        joinJoin
      }
    }
  `);

  const gyms = allGym.nodes;

  const augmentedState: GymsState = {
    ...initialState,
    filteredGyms: gyms,
    gyms,
    urls,
  };

  return (
    <GymSearchProvider initialState={augmentedState}>
      <ThemeProvider theme={pureGymTheme}>
        <SkipLink href="#main-content">
          {labels.shared.core.skipToMainContent}
        </SkipLink>
        <StyledPage>
          <BrandStateProvider>
            <Header />
            {children}
            <Footer />
          </BrandStateProvider>
        </StyledPage>
      </ThemeProvider>
    </GymSearchProvider>
  );
};

export default App;
