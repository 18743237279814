import { isNumber } from "lodash";

export const replaceTemplates = (
  label: string,
  replacements: Map<string, string | number>
) => {
  for (const [key, value] of replacements) {
    label = label.replaceAll(
      `{${key}}`,
      isNumber(value)
        ? new Intl.NumberFormat(process.env.GATSBY_LOCALE).format(value)
        : value
    );
  }
  return label;
};

export const getPluralLabel = (
  labels: any,
  plural: number,
  replacements: Map<string, string | number>
) => {
  const pluralRule = new Intl.PluralRules(process.env.GATSBY_LOCALE).select(
    plural
  );
  const matchingLabel = labels[pluralRule];
  return replaceTemplates(matchingLabel, replacements);
};
