export const collator = new Intl.Collator(process.env.GATSBY_LOCALE);

const numberFormat = new Intl.NumberFormat(process.env.GATSBY_LOCALE);

const pluralRules = new Intl.PluralRules(process.env.GATSBY_LOCALE);

const timeFormat = new Intl.DateTimeFormat(process.env.GATSBY_LOCALE, {
  hour: "numeric",
  minute: "numeric",
});
const weekdayFormat = new Intl.DateTimeFormat(process.env.GATSBY_LOCALE, {
  weekday: "long",
});

const dayMonthFormat = new Intl.DateTimeFormat(process.env.GATSBY_LOCALE, {
  day: "numeric",
  month: "long",
});

const fullYearFormat = new Intl.DateTimeFormat(process.env.GATSBY_LOCALE, {
  day: "numeric",
  month: "long",
  year: "numeric",
});

const fullDateFormat = new Intl.DateTimeFormat(process.env.GATSBY_LOCALE, {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  weekday: "long",
});

const distanceFormat = new Intl.NumberFormat(process.env.GATSBY_LOCALE, {
  style: "unit",
  maximumFractionDigits: 1,
  unit:
    process.env.GATSBY_LOCALE === "en-US" ||
    process.env.GATSBY_LOCALE === "en-GB"
      ? "mile"
      : "kilometer",
});

export {
  numberFormat,
  pluralRules,
  timeFormat,
  weekdayFormat,
  dayMonthFormat,
  fullYearFormat,
  fullDateFormat,
  distanceFormat,
};
