/**
 * Haversine formula for calculating distances between two points in KM.
 *
 * @see https://stackoverflow.com/a/21623206
 **/
const distanceBetween = (pointA, pointB) => {
  try {
    const p = 0.017453292519943295;
    const c = Math.cos;
    const a =
      0.5 -
      c((pointB.latitude - pointA.latitude) * p) / 2 +
      (c(pointA.latitude * p) *
        c(pointB.latitude * p) *
        (1 - c((pointB.longitude - pointA.longitude) * p))) /
      2;

    return 12742 * Math.asin(Math.sqrt(a));
  } catch (e) {
    console.log("Error calculating distance:", e);
    return undefined;
  }
};

const boundsToArray = (bounds) => {
  try {
    return [
      [bounds._northEast.lat, bounds._southWest.lng],
      [bounds._northEast.lat, bounds._northEast.lng],
      [bounds._southWest.lat, bounds._northEast.lng],
      [bounds._southWest.lat, bounds._southWest.lng],
    ];
  } catch (e) {
    return null;
  }
};

const calculateFarthest = (from, bounds) => {
  if (!from || !bounds) return from;

  const reducer = (farthestPoint, latlng) => {
    const distance = distanceBetween(from, latlng);
    return distance >= farthestPoint.distance
      ? { distance, latlng }
      : farthestPoint;
  };

  return boundsToArray(bounds).reduce(reducer, {
    distance: 0,
    latlng: undefined,
  }).latlng;
};

/**
 * Take a distance, if unitConvertingTo is Imperial then assume value is in KM and perform conversion then apply decimal places. If unitConvertingTo is Metric then just apply decimal places.
 * (we work in kilometres for all calculations; we display values
 * in miles if the locale expects it).
 *
 * @param {number} distance
 * @param {string} unitConvertingTo
 */
const prepareDistanceForDisplay = (distance, unitConvertingTo, toFixed = 1) => {
  const fixedNumber =
    unitConvertingTo === "Imperial"
      ? (distance / 1.609344).toFixed(toFixed)
      : distance.toFixed(toFixed);
  /** Remove the .0 from whole numbers */
  return parseFloat(fixedNumber);
};

/**
 * Take a distance, if unitConvertingFrom is Imperial then perform conversion to Metric and apply decimal places. If unitConvertingFrom is Metric then just apply decimal places.
 * (we work in kilometres for all calculations; we display values
 * in miles if the locale expects it).
 *
 * @param {number} distance
 * @param {string} unitConvertingFrom
 */
 const prepareDistanceForCalculation = (distance, unitConvertingFrom, toFixed = 1) => {
  const fixedNumber =
    unitConvertingFrom === "Imperial"
      ? (distance * 1.609344).toFixed(toFixed)
      : distance.toFixed(toFixed);
  /** Remove the .0 from whole numbers */
  return parseFloat(fixedNumber);
};

const isBetween = (bounds, point) => {
  const min = Math.min(bounds[0], bounds[1]);
  const max = Math.max(bounds[0], bounds[1]);

  return point > min && point < max;
};

/**
 * Adds the diametrically opposite point, therefore we always sensibly
 * centre the map on the actual starting point.
 */
const invertLatLng = (location, pivot) => {
  if (!location || !pivot) return location;

  const difference = [
    Math.abs(pivot[0] - location[0]),
    Math.abs(pivot[1] - location[1]),
  ];

  return [
    location[0] > pivot[0]
      ? pivot[0] - difference[0]
      : pivot[0] + difference[0],
    location[1] > pivot[1]
      ? pivot[1] - difference[1]
      : pivot[1] + difference[1],
  ];
};
export {
  calculateFarthest,
  prepareDistanceForDisplay,
  distanceBetween,
  isBetween,
  invertLatLng,
  prepareDistanceForCalculation,
};
