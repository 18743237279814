import { initCookieConsent } from "@puregym/cookieconsent";
import PluginHeader from "@puregym/gatsby-plugin-header";
import { useDeviceInfo } from "@puregym/ui";
import { ClientOnly } from "@puregym/utilities";
import { graphql, useStaticQuery } from "gatsby";
import { useEffect } from "react";
import labels from "../labels";
import { useBrand } from "../providers/brandState";

const Header = () => {
  const { urls } = useStaticQuery(graphql`
    {
      urls {
        authLogin
        authLogout
      }
    }
  `);
  const { isEmbeddedBrowser } = useDeviceInfo();
  const brand = useBrand();

  useEffect(() => {
    if (brand === "PGUK") {
      initCookieConsent("EN");
    }
  }, [brand]);

  const header = (
    <PluginHeader
      data-testid="header"
      urls={urls}
      brand={process.env.GATSBY_BRAND}
      brandName={process.env.GATSBY_BRAND_NAME}
      defaultLocale={process.env.GATSBY_DEFAULT_LOCALE}
      siteUrl={process.env.GATSBY_SITE_URL}
      labels={labels.shared.core}
      enableCookieConsent={brand === "PGUK"}
    />
  );

  return (
    <ClientOnly fallback={header}>
      {isEmbeddedBrowser ? null : header}
    </ClientOnly>
  );
};

export default Header;
