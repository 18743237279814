const labels = {
  pageHeading: 'Find your gym',
  search: 'Search',
  location: 'Search by city, town or postcode',
  joinLink: 'Join now',
  gymLink: 'More info',
  costBreakdown: {
    default: 'From {currentPrice} per month',
    full: 'From {currentPrice} per month + {currentJoiningFee} joining fee',
    striked: '{normalPrice} {currentPrice} per month',
    fullDiscountedPeriod: {
      one: 'for your first month + {joiningFee} joining fee',
      other: 'for your first {discountPeriod} months + {joiningFee} joining fee'
    }
  },
  currentLocation: 'Find a gym near me',
  successSearchMapHint: 'Tap on the pins to find out more about each gym.',
  backToAllGyms: 'Back to all gyms',
  miles: {
    one: '{distance} mile',
    other: '{distance} miles'
  },
  kilometers: {
    one: '{distance} kilometer',
    other: '{distance} kilometers'
  },
  mapView: 'Map view',
  mapViewTitle: 'Show gyms on a map',
  listView: 'List view',
  listViewTitle: 'Show gyms in a list',
  gymsList: 'List of gyms in your search area',
  resetGymSearch: 'Reset gyms',
  mapSearchLocationTooltip: 'Distances shown from here',
  loadingMapGyms: 'Loading gyms',
  gymDisplayToggle: 'Toggle between list view and map view',
  mapEnterFullScreen: 'Expand map to fullscreen',
  mapExitFullScreen: 'Exit fullscreen map',
  infoModalTriggerText: 'More info',
  infoModalHeading: '{brandName} - Find your Gym',
  classesSectionTitle: 'Classes included in your membership',
  membershipOptionsSectionTitle: 'Get the membership that suits you',
  managersSectionTitle: 'Meet the managers',
  findUsSectionTitle: 'How to find us',
  noContract: 'NO CONTRACT GYM - leave any time',
  overview: 'Overview',
  personalTrainers: 'Personal Trainers',
  classTimetable: 'Class Timetable',
  welcomeSectionTitle: 'Welcome to PureGym {gymName}',
  upgradeLink: 'Upgrade',
  membershipOptionsLink: 'Membership options',
  gyms: 'Gyms',
  classes: 'Classes',
  viewMap: 'View map',
  moreInformationTitle: 'More Information',
  searchResults: 'Search Results',
  ok: 'OK',
  noGymsFoundNearby: 'No gyms found nearby, showing all gyms. Click on the map pins for more information.',
  nearbyGyms: 'Nearby Gyms',
  viewAllGyms: 'view all gyms',
  address: 'Address',
  contactUs: 'Contact us',
  messenger: 'Messenger',
  twitter: 'Twitter',
  telephone: 'Telephone',
  email: 'Email',
  emailThisGym: 'Email this gym',
  twitterDm: 'Twitter DM',
  directions: 'Directions',
  instagram: 'Instagram',
  gymsFoundNearby: {
    one: '{resultCount} gym found nearby. Click on the map pins for more information.',
    other: '{resultCount} gyms found nearby. Click on the map pins for more information.'
  },
  searchCount: {
    one: '{resultCount} gym',
    other: '{resultCount} gyms'
  },
  personalTrainersUrlRoute: 'personal-trainers',
  classesUrlRoute: 'classes',
  overviewUrlRoute: 'overview',
  gymReviews: 'gym reviews',
  personalTrainersComingSoon: 'Personal Trainers will be available when the Gym opens.\n\nFor now, find out more about our Personal Training offering below.',
  classesComingSoon: 'When the gym opens your class timetable will be available here.\n\nWhile you wait take a look at the wide range of Fitness Classes you have to look forward to.',
  findOutMore: 'Find out more',
  gymDocumentationHeading: 'Gym Documentation',
  documentName: 'Evacuation information',
  joinUrlSegment: 'join',
  membersUrlSegment: 'members',
  bookAClassUrlSegment: 'book-a-class',
  gymsUrlSegment: 'gyms',
  personalTrainersHelpText: 'Select a personal trainer to find out more about them',
  threeSixtyTour: 'TAKE THE 360° TOUR',
  open24Hours: '24 hours/day, 7 days a week',
  openingHours: 'Opening Hours',
  unstaffedHours: 'View Unstaffed Hours',
  unstaffedHoursHeading: 'Unstaffed Hours',
  unstaffedHoursCloseLabel: 'Close unstaffed hours',
  openingHoursWeekday: 'Weekdays',
  openingHoursTimeRanges: 'Opening Hours',
  unstaffedHoursTimeRanges: 'Unstaffed Hours',
  unstaffedHoursWeekday: 'Weekdays',
  personalTrainersList: 'List of personal trainers available at this gym',
  findYourFavouriteClasses: 'Find your favourite classes',
  managersHelpText: 'At PureGym your team is always there to help you. We also have personal trainers on hand.',
  findYourPersonalTrainer: 'Find Your Personal Trainer',
  gymManager: 'Gym Manager',
  assistantManager: 'Assistant Gym Manager',
  breadcrumbs: {
    home: 'Home',
    city: 'Gyms in {cityName}'
  },
  lowPrices: 'low prices',
  classesMetaTitle: 'Fitness, Yoga & Zumba Classes in {GymName}',
  classesMetaDescription: 'Enjoy getting fit with others and join PureGym {GymName} for just {LowestPrice}, we\'ve got a huge range of fitness classes to suit all needs!',
  personalTrainersMetaTitle: 'Personal Trainers in {GymName}',
  personalTrainersMetaDescription: 'At PureGym {GymName}, our personal trainers can offer sessions and classes to help you reach your fitness goals for just {LowestPrice}. Join now!',
  gymComingSoon: 'PureGym {gymName} is coming soon',
  checkSoonForUpdates: 'Check back soon for updates and offers! When the gym has an opening date you’ll be able to sign up here.',
  status: {
    blocked: 'Closed',
    comingSoon: 'Coming soon',
    paused: 'Temporarily closed',
    preJoin: 'Opening soon',
    open: 'Open',
    reopeningSoon: 'Reopening soon'
  },
  errors: {
    genericApiError: 'We were unable to find your location',
    noGyms: 'Sorry, no gyms were found that matched your search.'
  },
  gymCard: {
    specialOffer: 'Special offer',
    openingOffer: 'Opening offer',
    costBreakdown: 'From {currentPrice} per month',
    discountedCostBreakdown: {
      one: 'for your first month + {joiningFee} joining fee',
      other: 'for your first {discountPeriod} months + {joiningFee} joining fee'
    },
    offerCostBreakdown: '{normalPrice} {currentPrice} per month'
  },
  timetable: {
    sectionHeading: 'Classes',
    selectors: {
      weekView: 'Week view',
      listView: 'List view',
      viewModeLabel: 'Calendar mode',
      previousWeek: 'Previous week',
      nextWeek: 'Next week'
    },
    classType: {
      buttonLabel: 'Show me',
      all: 'All Inductions & Classes',
      induction: 'Inductions Only',
      class: 'Classes Only',
      digital: 'Digital Classes Only',
      typeGroupLabel: 'Class types',
      nameGroupLabel: 'Available classes'
    },
    classDetails: {
      digitalType: 'Digital',
      inductionType: 'Induction',
      classDuration: '({duration} mins)',
      classCapacity: {
        one: '{capacity} space left',
        other: '{capacity} spaces'
      },
      classNotAvailable: 'Unavailable',
      noAvailableClasses: 'No classes available on this day'
    },
    timeRange: {
      buttonLabel: 'Time of day',
      allDay: 'All day',
      morning: 'Morning (00.00 - 09:00)',
      noon: 'Noon (09:00 - 12:00)',
      afternoon: 'Afternoon (12:00 - 17:00)',
      evening: 'Evening (17:00 - 23:59)'
    },
    calendar: {
      today: 'Today',
      tomorrow: 'Tomorrow',
      classTitle: 'Class',
      duration: 'Time (Duration)',
      studio: 'Studio',
      instructor: 'Instructor',
      info: 'Class info'
    },
    classModal: {
      duration: 'Duration',
      timeDate: 'Time & Date',
      studio: 'Studio',
      instructor: 'Instructor',
      energy: 'Calories',
      capacity: 'Spaces',
      energyValue: 'Estimated to burn {energyConsumption} kCal',
      durationValue: '{duration} minutes',
      buttonModal: 'View class details',
      bookingsUrl: 'Manage your bookings'
    },
    introductionText: 'Whether you want to burn calories, build strength, or simply get fit and have fun, we have classes for all goals, suitable for all abilities! There\'s a huge variety of fitness classes each week included in your membership - simply log in and book on the website, or via the free PureGym app.',
    noAvailableClasses: 'Sorry, classes are temporarily unavailable on the website. For now, please visit the gym in person to view the class timetable and book classes.',
    introductionInfo: 'Class information shown here is only representative of the last update and may vary. To access live booking information including availability and class instructors, please log in.'
  },
  gymBanner: {
    anchorTitle: 'Join {gymName}'
  },
  reviews: {
    scoreLabel: '{value} out of 5 star rating on Trustpilot',
    totalReviewCount: {
      one: 'Based on {reviewCount} review',
      other: 'Based on {reviewCount} reviews'
    },
    widgetLabel: 'Trustpilot reviews',
    logo: 'Trustpilot',
    explanation: 'Showing our latest reviews',
    pageTitle: '{gym} gym reviews'
  },
  hero: {
    personalTrainersHeading: 'Personal trainers at {gymHeading}',
    classesHeading: 'Fitness classes at {gymHeading}'
  },
  cityPages: {
    indexPageHeading: 'Find your nearest gym',
    buttonGyms: 'View gyms',
    buttonJoin: 'Join now',
    signoffText: 'Close to work or home, we\'ve got you covered',
    featuresHeading: 'What makes our gyms great?',
    featuresListLabel: 'List of features',
    mapNearHeading: 'Our gyms near {cityName}',
    mapInHeading: 'Our gyms in {cityName}'
  },
  gymFinder: {
    heading: 'Find your gym',
    search: 'Search',
    currentLocation: 'Find a gym near me',
    location: 'Search by city, town or postcode',
    mapSearchLocationTooltip: 'Distances shown from here',
    backToAllGyms: 'Back to all gyms',
    successSearchMapHint: 'Tap on the pins to find out more about each gym.',
    resetGymSearch: 'Reset gyms',
    searchResults: 'Search Results',
    searchCount: {
      one: '{resultCount} gym',
      other: '{resultCount} gyms'
    },
    noGymsFoundNearby: 'No gyms found nearby, showing all gyms. Click on the map pins for more information.',
    gymsFoundNearby: {
      one: '{resultCount} gym found nearby. Click on the map pins for more information.',
      other: '{resultCount} gyms found nearby. Click on the map pins for more information.'
    },
    gymsNationwide: 'Choose from over {joinableGyms} gyms nationwide',
    searchSupportText: 'You\'ll find everything you need to know about your local gym, including what\'s inside, how to find us, parking information and more.'
  },
  citiesList: {
    gymsNear: 'Gyms near {cityName}',
    gymsIn: 'Gyms in {cityName}',
    gymsNearCount: {
      one: '{joinableGyms} gym near {cityName} to choose from',
      other: '{joinableGyms} gyms near {cityName} to choose from'
    },
    gymsInCount: {
      one: '{joinableGyms} gym in {cityName} to choose from',
      other: '{joinableGyms} gyms in {cityName} to choose from'
    },
    label: 'List of cities'
  },
  cityCards: {
    legendParking: 'Free parking',
    legendOpenAnytime: 'Open 24 hours',
    cardsListLabel: 'List of city joinable gyms'
  },
  buttons: {
    joinNow: 'Join now',
    moreInfo: 'More info',
    viewGyms: 'View gyms'
  },
  shared: {
    core: {
      cookieBannerMessage: 'By using this site, you agree with our use of cookies.',
      menuButton: 'Menu',
      closeMenuButton: 'Close',
      logInButton: 'Log In',
      logOutButton: 'Log Out',
      cookieBannerMoreLink: 'Find out more.',
      menuTitle: 'Open main navigation',
      closeMenuTitle: 'Close main navigation',
      menuBackText: 'Back to previous',
      languageSelectorTitle: 'Change Language',
      menuLabel: 'Main navigation',
      loading: 'Loading...',
      skipToMainContent: 'Skip to main content',
      home: 'Home',
      modalClose: 'Close this modal',
      iFrameNotSupported: 'Your browser does not support iframes.',
      cookieConsentModify: 'Cookie Preferences',
      general: 'We have had a little problem which is normally only temporary.',
      languageSelectorText: 'Language',
      userMenuText: 'Open user menu',
      unsupportedBrowserText: 'You are using an unsupported browser.',
      unsupportedBrowserLinkText: '',
      next: 'Next',
      previous: 'Previous',
      regionSwitcherLabel: 'Choose another country to see content specific to your location',
      continue: 'Continue',
      view: 'View',
      seeAll: 'See all',
      learnMore: 'Learn more',
      search: 'Search',
      termsAndConditions: 'See T&Cs for details'
    }
  },
  joinMonthlyPackages: {
    packageTableCaption: 'Select a gym package',
    packageTableLabel: 'Select a gym package',
    offPeakDescription: 'Limited hours of access',
    standardDescription: 'Our standard membership',
    premiumDescription: 'Our best membership',
    ribbonText: 'Special offer',
    button: 'Select',
    buttonSelected: 'Selected',
    buttonLink: 'Join',
    normalCostBreakdown: 'per month + {joiningFee} joining fee',
    discountedCostBreakdowns: {
      one: 'for your first month + {joiningFee} joining fee',
      other: 'per month for your first {discountPeriod} months + {joiningFee} joining fee'
    },
    iconMoreInfoText: 'More information',
    iconIncludedText: 'Included',
    iconExcludedText: 'Not included',
    gymAccess: {
      one: 'Access to 1 other gym',
      other: 'Access to {gyms} other gyms'
    },
    gymAccessNoPremium: 'Access to other gyms',
    bookingDays: {
      one: '1 day',
      other: '{days} days'
    },
    restrictedHours: 'Limited hours',
    restrictedHoursHeading: 'Time restrictions',
    restrictedHoursWeekday: 'Weekdays',
    restrictedHoursTimeRanges: 'Permitted times',
    restrictedHoursCloseLabel: 'Close time restrictions',
    modalCloseLabel: 'Close package benefit explanation',
    salesTax: '(plus tax)',
    closestGyms: 'Closest to your home gym',
    excludedGyms: 'Excluded gyms',
    includedGyms: {
      one: '{gymCount} included gym',
      other: '{gymCount} included gyms'
    },
    packageFeatures: {
      frequencyLabel: 'Use the gym anytime',
      contractLabel: 'No contract - leave anytime',
      bookingsLabel: 'Book classes in advance',
      buddyLabel: {
        one: 'Bring a guest {frequency} time a month',
        other: 'Bring a guest {frequency} times a month'
      },
      buddyExplanation: {
        one: 'Plus members can visit their home gym with a nominated friend at no extra cost {frequency} time a month. Friends can only visit the gym at the same time as the Plus member.',
        other: 'Plus members can visit their home gym with a nominated friend at no extra cost up to {frequency} times per month. Friends can only visit the gym at the same time as the Plus member.'
      },
      classesLabel: 'Digital classes included with membership',
      classesInstructorLabel: 'Instructor led classes',
      freezeLabel: 'Freeze your membership anytime',
      freezeExplanation: 'Off-peak and Core members can freeze their membership for up to 3 months from {freezePrice}. Plus members can freeze their membership at no additional cost for up to 3 months in a 12-month period.',
      massageLabel: 'Recover with a daily massage',
      massageExplanation: 'As a Plus member, you can get access to a daily 10-minute sports massage at no additional cost, on our state-of-the-art massage chairs. The chairs use heated water to apply constant pressure, at a level you can set yourself and working on the whole body or just where you need it most.',
      bodytrackLabel: 'Track your progress with body composition scans',
      bodytrackExplanation: 'Plus members can use our body composition scans in the gym to measure metrics such as their bodyweight, BMI, muscle and fat free mass at no additional cost.',
      yangaLabel: 'Rehydrate with Sports Water',
      yangaExplanation: 'As a Plus member, you can get access to Sports Water.',
      buddyUnlimitedLabel: 'Bring a guest unlimited times a month',
      buddyUnlimitedExplanation: 'Plus members can visit their home gym with a nominated friend at no extra cost unlimited times a month. Friends can only visit the gym at the same time as the Plus member.',
      freezePlusExplanation: 'Plus members can freeze their membership at no additional cost for up to 3 months in a 12-month period.',
      multiAccessLabel: {
        one: 'Access to {gymCount} gym',
        other: 'Access to {gymCount} gyms'
      },
      biggerDiscountsLabel: 'Bigger discounts on brands',
      biggerDiscountsExplanation: 'Plus members can enjoy exclusive discounts from tops brands, ranging from clothing, food and more at their fingertips. Get quick and easy access to all the exclusive deals anytime you want by logging in to your Members Area.',
      classesDigitalLabel: 'Digital classes included with membership',
      classesGenericLabel: 'Classes included with membership',
      classesInstructorExplanation: 'Get access to instructor led classes to help motivate you and improve your fitness in addition to the digital classes that come with all memberships.',
      classesGenericExplanation: 'Unlimited classes included with membership. Please note if you are under 18 you cannot book classes.'
    },
    experiments: {
      contractFeature: 'Join today cancel anytime',
      unlimitedClassesFeature: 'Unlimited classes included',
      tableHeading: 'Included in all membership',
      gymAccessFeature: 'Number of gym access',
      gymAccessExplanation: 'Select Plus and you will get access to every other full priced PureGym at the same price or lower than your chosen home gym. If you want to add a gym which is not automatically included, just select \'add more gyms\' once you\'ve selected Plus. The full list of included gyms is shown below, including those closest to your current choice.',
      limitedHoursLabel: 'Limited hrs'
    }
  },
  distance: {
    one: '{distance} mile',
    other: '{distance} miles'
  }
}
 
export default labels;