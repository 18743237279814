exports.components = {
  "component---src-pages-city-404-tsx": () => import("./../../../src/pages/city/404.tsx" /* webpackChunkName: "component---src-pages-city-404-tsx" */),
  "component---src-pages-city-city-slug-index-tsx": () => import("./../../../src/pages/city/{City.slug}/index.tsx" /* webpackChunkName: "component---src-pages-city-city-slug-index-tsx" */),
  "component---src-pages-city-index-tsx": () => import("./../../../src/pages/city/index.tsx" /* webpackChunkName: "component---src-pages-city-index-tsx" */),
  "component---src-pages-gyms-404-tsx": () => import("./../../../src/pages/gyms/404.tsx" /* webpackChunkName: "component---src-pages-gyms-404-tsx" */),
  "component---src-pages-gyms-gym-slug-index-tsx": () => import("./../../../src/pages/gyms/{Gym.slug}/index.tsx" /* webpackChunkName: "component---src-pages-gyms-gym-slug-index-tsx" */),
  "component---src-pages-gyms-gym-slug-personal-trainers-tsx": () => import("./../../../src/pages/gyms/{Gym.slug}/personal-trainers.tsx" /* webpackChunkName: "component---src-pages-gyms-gym-slug-personal-trainers-tsx" */),
  "component---src-pages-gyms-gym-slug-timetable-tsx": () => import("./../../../src/pages/gyms/{Gym.slug}/timetable.tsx" /* webpackChunkName: "component---src-pages-gyms-gym-slug-timetable-tsx" */),
  "component---src-pages-gyms-index-tsx": () => import("./../../../src/pages/gyms/index.tsx" /* webpackChunkName: "component---src-pages-gyms-index-tsx" */)
}

