import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { ExtendedGym } from "./models/gyms";
import gymSearchReducer from "./reducer";

export type GymsState = {
  gyms: ExtendedGym[];
  filteredGyms: ExtendedGym[];
  mapReady: boolean;
  isSearching: boolean;
  displaySearchError: boolean;
  searchBounds: number[][];
  searchLocation: Location;
  searchSuccessfullyPerformed: boolean;
  numSearchResults: number;
  noGymsFoundInRange: boolean;
  searchTerm: string;
  urls: Record<string, string>;
};

/**
 * Context for Gym Search. State for search term, map data etc.
 *
 * Follows patterns outlined here:
 * @see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const GymSearchStateContext = createContext<GymsState>(null);
const GymSearchDispatchContext = createContext();

const useGymSearchState = () => {
  const context = useContext(GymSearchStateContext);
  if (context === undefined) {
    throw new Error(
      "useGymSearchState must be used within a GymSearchProvider"
    );
  }
  return context;
};

const useGymSearchDispatch = () => {
  const context = useContext(GymSearchDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useGymSearchDispatch must be used within a GymSearchProvider"
    );
  }
  return context;
};

const useGymSearch = () => [useGymSearchState(), useGymSearchDispatch()];

const GymSearchProvider = ({ children, initialState }) => {
  const mounted = useRef(true);
  const [state, dispatch] = useReducer(gymSearchReducer, initialState);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  // We need to keep track off whether the component is still mounted, otherwise async actions
  // will still try to dispatch actions to an unmounted component which react is not happy with
  const dispatchIfMounted = useCallback(
    (body) => {
      if (mounted.current) {
        dispatch(body);
      }
    },
    [dispatch]
  );

  return (
    <GymSearchStateContext.Provider value={state}>
      <GymSearchDispatchContext.Provider value={dispatchIfMounted}>
        {children}
      </GymSearchDispatchContext.Provider>
    </GymSearchStateContext.Provider>
  );
};

export {
  GymSearchProvider,
  useGymSearch,
  useGymSearchDispatch,
  useGymSearchState,
};
