import { GYM_TAB_TITLES, GYM_TAB_TITLES_NO_PTS } from "../constants/ui";

const calculateSelectedTab = (
  tabLabel: string,
  ptsEnabled: boolean
): number => {
  const tabTitles = ptsEnabled ? GYM_TAB_TITLES : GYM_TAB_TITLES_NO_PTS;

  if (tabTitles.indexOf(tabLabel) !== -1) {
    return tabTitles.indexOf(tabLabel);
  } else {
    console.log(
      `Desired tab ${tabLabel} not found in tab titles... defaulting to index 0`
    );
    return 0;
  }
};

export { calculateSelectedTab };
