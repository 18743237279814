import { createContext, useContext, useEffect, useState } from "react";
import { getDeviceInfo } from "@puregym/utilities";

export const useBrand = () => useContext(BrandContext);

export const BrandContext = createContext<string>(undefined);

export const BrandStateProvider = ({ children }) => {
  const [brand, setBrand] = useState(process.env.GATSBY_BRAND);
  const { isBrowser } = getDeviceInfo();

  useEffect(() => {
    if (process.env.GATSBY_TEST_MODE === "true") {
      const brandOverride =
        isBrowser && window.localStorage.getItem("CYPRESS_BRAND");
      if (brandOverride) {
        setBrand(brandOverride);
      }
    }
  }, []);

  return (
    <BrandContext.Provider value={brand}>{children}</BrandContext.Provider>
  );
};

export default BrandStateProvider;
