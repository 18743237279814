import { isNumber } from "lodash";
import { numberFormat } from "./intl";

export const replaceTemplates = (
  label: string,
  replacements: Record<string, string | number>
) => {
  for (const [key, value] of Object.entries(replacements)) {
    label = label.replaceAll(
      `{${key}}`,
      isNumber(value) ? numberFormat.format(value) : value
    );
  }
  return label;
};
